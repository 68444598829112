<template>
  <div class="container">
    <!-- nav部分 -->
    <nav-bar></nav-bar>
    <!--Web主体-->
    <web-main></web-main>
    <!--页脚-->
    <footer-layout></footer-layout>
    <!--右侧边条-->
    <right-side></right-side>
  </div>
</template>

<script>
import {
  WebMain,
  NavBar,
  FooterLayout,
  RightSide
} from './components'

export default {
  name: 'LayoutItem',
  components: {
    RightSide,
    FooterLayout,
    WebMain,
    NavBar
  },
}
</script>

<style scoped lang="scss">

</style>
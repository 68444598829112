<template>
  <div class="footer">
    <div class="layui-container">
      <p class="footer-web">
        <a :href="item.link" v-for="(item, index) in friendlyLinks" :key="index">{{item.name}}</a>
      </p>

      <div class="foot">
        <div class="wrap clearfix">
          <span style="width:1000px; margin:0 auto;display: block;">
            <p>盐城云想网络成立于2018年，专业开发网站建设，提供解决方案、网站营销活动，定制各类应用、系统平台，案例众多！</p>
          </span>
          Copyright © 2018 - {{currentYear}}
          <a href="/" target="_blank">{{siteInfo.name}}</a>
          版权所有 违者必究
          <a :href="siteInfo.homeLink" target="_blank">{{siteInfo.homeLink}}</a>
          All Rights Reserved <br>
          手机/微信：{{siteInfo.telephone}}  QQ：{{siteInfo.qq}} 地址：{{siteInfo.address}}<br>
          电信与信息服务业务经营许可证 工信部备案号码
          <a href="https://beian.miit.gov.cn/" target="_blank">{{siteInfo.icp}}</a>
          技术支持：
          <a href="https://www.yunshy.com" target="_blank">{{siteInfo.shortName}}</a><br>
          盐城云想网络公司专注于
          <a href="https://www.yunshy.com/website/" target="_blank" title="盐城网站建设">盐城网站建设</a>
          服务，专业
          <a href="/" target="_blank" title="盐城网络推广">盐城网络推广</a>
          和
          <a href="/" target="_blank" title="盐城网站推广">盐城网站推广</a>
          团队。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {listFriendlyLink} from "@/api/common";

export default {
  name: 'FooterLayout',
  data(){
    return {
      currentYear: 2024,
      siteInfo: {
        name: '盐城云想网络科技有限公司',
        shortName: '云想网络',
        address: '盐城市盐南高新区珠溪路1号珠溪铭苑17幢103-6室(CNW)',
        telephone: '17551752209',
        homeLink: 'https://www.yunshy.com',
        qq: '927428662',
        wechat: '',
        icp:'苏ICP备18067590号-1'
      },
      friendlyLinks: [
        {link: 'https://www.baidu.com', name: '百度一下'},
        {link: 'https://www.csdn.net', name: 'CSDN'}
      ]
    }
  },
  created() {
    this.listFriendlyLinks();
  },
  methods: {
    // 获取友情链接
    listFriendlyLinks(){
      // listFriendlyLink().then(res => {
      //
      // })
    }
  }
}
</script>
<style scoped lang="scss">
.foot {
  overflow: hidden;
  padding: 10px 0;
  text-align: center;
  line-height: 25px;
  color: #FFFFFF;
}
.foot a{color: red;}
</style>
<template>
  <div class="swt_main">
    <div class="swt_right">
      <p class="swt_p1">
        <a href="http://pft.zoosnet.net/LR/Chatpre.aspx?id=PFT47855484&amp;lng=cn" target="_blank" title="在线咨询">
          <img src="./images/zixun.png">
          <span id="divRsjs" class="divRsjs">59</span>
        </a>
      </p>
      <p class="swt_p2">
        <a href="tencent://message/?uin=927428662&amp;Site=云想网络&amp;Menu=yes" title="QQ咨询">
          <img src="./images/swtright_08.png">
        </a>
      </p>
      <p class="swt_p3" id="follow">
        <a class="gz_pic" title="关注">
          <img src="./images/swtright_14.png">
        </a>
        <a class="wx_pic" title="微信二维码">
          <img src="./images/swtright_13.png">
        </a>
        <a class="xl_pic" title="新浪微博二维码">
          <img src="./images/swtright_12.png">
        </a>
      </p>
      <p class="swt_p5" id="phone" style="width: 68px; left: 0px; overflow: hidden;">
        <a class="dh_pic" title="免费电话">
          <img src="./images/swtright_16.png">
        </a>
      </p>
      <div class="dh_right" style="width: 0px; left: -1px; padding-left: 0px; overflow: hidden;">17551752209</div>
      <p></p>
      <div class="swt_top" onclick="scrollToTop()" title="返回顶部">
        <a class="fh_top" title="免费电话">
          <img src="./images/top.png">
        </a>
      </div>
    </div>
    <div class="swt_weixin"><span>扫描二维码<br>关注微信官方账号 </span></div>
    <div class="swt_xinlan"><span>扫描二维码<br>关注新浪微博官方账号 </span></div>
  </div>
</template>

<script>
  export default {
    name: 'RightSide',
    data(){
      return {

      }
    },
    methods: {

    }
  }
</script>


<style scoped lang="scss">
#swt_right{z-index:99999;position:fixed;right:10px;top:30%;margin-top:-150px;_margin-top:-310px;_position:absolute;_top:expression(eval(document.documentElement.clientHeight/2+document.documentElement.scrollTop)); z-index:999999999999999999999999999999}
#swt_right_zx{width:109px;height:26px;position:absolute;left:12px;top:186px;}
#swt_right_qq{width:109px;height:26px;position:absolute;left:12px;top:217px;}
#swt_right_ms{width:109px;height:26px;position:absolute;left:12px;top:249px;}
#swt_right_yq{width:62px;height:22px;position:absolute;left:4px;top:394px;}
#swt_right_xq{width:62px;height:22px;position:absolute;left:68px;top:394px;}
.swt_main{ width:68px; height:330px; position: fixed; top:50%; margin-top:-193px; right:0; z-index:998}
.swt_right{ width:68px; height:330px; position:relative;}
.swt_right p{ width:68px; height:68px; margin-bottom:1px; position:relative; display:block; cursor:pointer; z-index:998;}
.swt_right p span#divRsjs{ width:19px; height:10px; color:#fff; text-align:center; position:absolute; left:37px; top:9px; font-size:10px; display:block; z-index:999; font-weight:bold}
.swt_right p.swt_p1 a,.swt_right p.swt_p2 a,.swt_right p.swt_p4 a,.swt_right p.swt_p6 a{ width:68px; height:68px; display:block; background:#16baaa;}
.swt_right p.swt_p1 a:hover,.swt_right p.swt_p2 a:hover,.swt_right p.swt_p4 a:hover,.swt_right p.swt_p6 a:hover{ background:#16baaa;}
.swt_right .swt_top{ width:68px; height:68px; cursor:pointer; margin-top:-69px; position:relative; z-index:9999}
p.swt_p3{ position:relative; width:68px; height:68px;}
a.gz_pic{ display:block;position: absolute; right:0; background:#16baaa; width:68px; height:68px;}
a.wx_pic,a.xl_pic{ display:none; position:absolute; background:#16baaa; width:68px; height:68px; z-index:9999999999999999999999999}
a.wx_pic:hover,a.xl_pic:hover,a.tx_pic:hover{ background:#16baaa; display:none}
a.wx_pic{ right:51px;}
a.xl_pic{ right:102px;}
a.tx_pic{ right:153px;}
.swt_weixin,.swt_xinlan{ width:152px; height:180px;  position: absolute; left:-153px; top:200px; z-index:99999999; display:none;}
.swt_weixin span,.swt_xinlan span{ display:block; width:100%; margin-top:135px; text-align:center; font:13px/18px "微软雅黑"; color:#fff;}
/*弹出电话一栏*/
p.swt_p5{ width:68px; height:68px; display:block; overflow:hidden; position:relative}
a.dh_pic,a.fh_top{ display:block;position: absolute; top:0; right:0; width:68px; height:68px; background-color:#16baaa; overflow:hidden}
a.dh_pic:hover{ background:#16baaa;}
a.dh_pic img{ width:68px; height:68px;}
.dh_right{ width:0; height:53px; padding:15px 0 0; margin-right:1px; line-height:39px; text-indent:9px; font-size:26px; background:#16baaa; color:#fff; position: absolute; top:207px; left:-1px; }
.textk01{ width:110px; height:21px; font:12px/21px "宋体"; border:0; color:#666; display:none; margin:3px 0 0 7px; background: transparent; float:left}
.mfdh_btn01{ width:68px; height:25px; float:left; margin:1px 0 0 5px; border:0; background:transparent; cursor:pointer;}
</style>
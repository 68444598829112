<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </transition>
</template>
<script>
export default {
  name: 'WebMain',
}
</script>
<style scoped lang="scss">

</style>
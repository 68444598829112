<template>
  <div class="nav index">
    <div class="layui-container">
      <!-- 公司logo -->
      <div class="nav-logo">
        <a href="">
          <img src="@/assets/logo.png" alt="网络公司">
        </a>
      </div>
      <div class="nav-list">
        <button>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul class="layui-nav">
          <li :class="activeMenu === item.link ? 'layui-nav-item layui-this' : 'layui-nav-item' " v-for="(item, index) in navList" :key="index">
            <a href="#" @click="jumpLink(item.link)">{{item.title}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavBar',
    data() {
      return {
        activeMenu: '/',
        navList: [
          {title: '首页', link: '/'},
          {title: '产品', link: '/product'},
          {title: '动态', link: '/news'},
          {title: '案例', link: '/case'},
          {title: '关于', link: '/about'}
        ]
      }
    },
    computed: {

    },
    mounted() {

    },
    created() {
      console.log(this.$route.path)
    },
    methods: {
      jumpLink(path){
        this.activeMenu = path
        this.$router.push({path:path})
      }
    }
  }
</script>
<style scoped lang="scss">

</style>

const constantRoutesComponents = {
    home: () => import('@/views/home'),
    product: () => import('@/views/product'),
    news: () => import('@/views/news'),
    news_detail: () => import('@/views/news/detail'),
    case: () => import('@/views/case'),
    about: () => import('@/views/about'),
    login: () => import('@/views/login/index'),
    _404: () => import('@/views/404'),
}

import Layout from "@/layout";

export const asyncRoutesComponents = {
    home: () => import('@/views/home')
}
export const constantRoutes = [
    {
        path: '/404',
        component: constantRoutesComponents._404,
        hidden: true
    },
    {
        path: '/login',
        component: constantRoutesComponents.login,
        hidden: true
    },
    {
        path: '',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '',
                component: constantRoutesComponents.home,
            },
            {
                path: '/portal',
                component: constantRoutesComponents.home,
            },
            {
                path: '/product',
                component: constantRoutesComponents.product
            },
            {
                path: '/news',
                component: constantRoutesComponents.news
            },
            {
                path: '/news/detail',
                component: constantRoutesComponents.news_detail
            },
            {
                path: '/case',
                component: constantRoutesComponents.case
            },
            {
                path: '/about',
                component: constantRoutesComponents.about
            },
        ]
    }
]